// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-templates-docs-doc-page-jsx": () => import("./../../../src/templates/docs/doc-page.jsx" /* webpackChunkName: "component---src-templates-docs-doc-page-jsx" */),
  "component---src-templates-docs-index-jsx": () => import("./../../../src/templates/docs/index.jsx" /* webpackChunkName: "component---src-templates-docs-index-jsx" */),
  "component---src-templates-index-jsx": () => import("./../../../src/templates/index.jsx" /* webpackChunkName: "component---src-templates-index-jsx" */)
}

